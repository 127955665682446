/* You can add global styles to this file, and also import other style files */
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';
/*@import 'https://material.io/resources/color/#!/?view.left=0&view.right=0&primary.color=FAFAFA&secondary.color=fda085&secondary.text.color=FAFAFA';*/
.mat-mdc-dialog-container {
  border-radius: 8px !important;
}
.modal-dialog-footer .btn-custom {
  min-width: 3.5em;
}

.modal-dialog-footer .btn-custom:not(:last-child){
  margin-left: 0.2em;
}
